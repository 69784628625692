import React, { useState } from "react"
import Layout from "../components/layout"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import Masonry from "react-masonry-css"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, ButtonBase, Modal, Backdrop, Fade } from "@material-ui/core"
import "../styles/component.css"
import SEO from "../components/seo"
import website from "../../config/website"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: "5% 14% 5% 14%",
    width: "100%",
    marginTop: "100px",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 5% 4%",
      marginTop: "25%",
    },
  },
  secondContainer: {
    display: "flex",
    padding: "5% 18% 5% 18%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 5% 5%",
    },
  },
  imageWrapper: {
    margin: "5px",
  },
  image: {
    width: "240px",
    objectFit: "cover",
    margin: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
    [theme.breakpoints.up("xl")]: {
      width: "300px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    border: "solid 0.5px rgba(255, 255, 255, 0.1)",
    boxShadow: theme.shadows[0],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    "&:focus": {
      outline: "none",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  modalImage: {
    maxHeight: "70vh",
    paddingTop: "30px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70vw",
      maxHeight: "auto",
      paddingTop: "10px",
    },
  },
  modalText: {
    color: "#ffffff",
    fontSize: "0.8em",
  },
}))

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 2,
}

const Gallery = ({ data }) => {
  const classes = useStyles()
  const masonries = data.allContentfulAsset.edges
  const [open, setOpen] = useState(false)
  const [targetsrc, setTargetsrc] = useState(null)
  const [targetalt, setTargetalt] = useState("")

  const handleOpen = e => {
    setOpen(true)
    setTargetsrc(e.target.src)
    setTargetalt(e.target.alt)
    console.log(e.target.alt)
  }

  const handleClose = () => {
    setOpen(false)
    setTargetsrc(null)
    setTargetalt("")
  }

  return (
    <>
      <Layout>
        <SEO
          title={`매건프로젝트 갤러리 | ${website.titleAlt}`}
          desc="매건프로젝트와 함께 한 다양한 웨딩 및 행사 이벤트 사례를 감상할 수 있는 갤러리입니다. 마음에 드시는 사진을 보고 의뢰해주세요."
        />
        <Grid container className={classes.container}>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {masonries.map(item => (
              <div key={item.node.id}>
                <ButtonBase onClick={handleOpen}>
                  <Img
                    fluid={item.node.fluid}
                    src={item.node.file.url}
                    alt={item.node.title}
                    className={classes.image}
                  />
                </ButtonBase>
              </div>
            ))}
          </Masonry>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 1000,
            }}
            disableAutoFocus={true}
            targetsrc={targetsrc}
            targetalt={targetalt}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <div id="transition-modal-description">
                  <img
                    src={targetsrc}
                    className={classes.modalImage}
                    alt={targetalt}
                  />
                </div>
                <p id="transition-modal-title" className={classes.modalText}>
                  {targetalt}
                </p>
              </div>
            </Fade>
          </Modal>
        </Grid>
      </Layout>
    </>
  )
}

const props = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulAsset(limit: 50) {
            edges {
              node {
                id
                title
                file {
                  url
                }
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      `}
      render={data => <Gallery data={data} {...props} />}
    />
  )
}

export default Gallery
